import React, { useState, useEffect, useRef, useCallback } from 'react';
import './EatsApp.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import UniverseData from './UniverseData';
import UpgradeTab from './components/UpgradeTab';
import BoostTab from './components/BoostTab';
import SoonTab from './components/SoonTab';
import clickerImage from '../public/clicker-image.png';

const DamageIndicator = ({ x, y, damage }) => (
  <div className="damage-indicator" style={{ left: x, top: y }}>
    {damage}
  </div>
);

function EatsApp({ setIsTabOpen }) {
  const [gameState, setGameState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(null);
  const [isImageDistorted, setIsImageDistorted] = useState(false);
  const [isTabOpenState, setIsTabOpenState] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [damageIndicators, setDamageIndicators] = useState([]);

  const activityTimeoutRef = useRef(null);
  const clickerRef = useRef(null);

  useEffect(() => {
    const initUser = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const userId = window.Telegram.WebApp.initDataUnsafe.user.id;

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/init_user`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ user_id: userId })
        });

        if (!response.ok) throw new Error('Failed to initialize user');

        const userData = await response.json();
        await UniverseData.initialize(userData);
        setGameState(userData);
      } catch (error) {
        console.error('Error initializing user:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    initUser();

    return () => {
      UniverseData.cleanup();
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (UniverseData.localData) {
        setGameState(UniverseData.getCurrentData());
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const handleInteraction = useCallback((e) => {
    e.preventDefault();
    if (!gameState || gameState.energy < 1) return;

    const clicked = UniverseData.handleClick(gameState.damageLevel);
    if (!clicked) return;

    setIsImageDistorted(true);

    const rect = clickerRef.current.getBoundingClientRect();
    const x = e.clientX || (e.touches && e.touches[0].clientX);
    const y = e.clientY || (e.touches && e.touches[0].clientY);

    const newIndicator = {
      id: Date.now() + Math.random(),
      x: x - rect.left,
      y: y - rect.top,
      damage: gameState.damageLevel
    };

    setDamageIndicators(prev => [...prev, newIndicator]);

    setTimeout(() => {
      setDamageIndicators(prev => prev.filter(indicator => indicator.id !== newIndicator.id));
    }, 1000);

    setGameState(UniverseData.getCurrentData());

    if (activityTimeoutRef.current) {
      clearTimeout(activityTimeoutRef.current);
    }

    activityTimeoutRef.current = setTimeout(() => {
      setIsImageDistorted(false);
    }, 200);
  }, [gameState]);

  useEffect(() => {
    const clicker = clickerRef.current;
    if (clicker) {
      clicker.addEventListener('click', handleInteraction);
      clicker.addEventListener('touchstart', handleInteraction, { passive: false });

      return () => {
        clicker.removeEventListener('click', handleInteraction);
        clicker.removeEventListener('touchstart', handleInteraction);
      };
    }
  }, [handleInteraction]);

  const handleTabOpen = (tab) => {
    setActiveTab(tab);
    setIsTabOpenState(true);
    setIsTabOpen?.(true);
    setShowButtons(false);
  };

  const handleBackButtonClick = () => {
    setActiveTab(null);
    setIsTabOpenState(false);
    setIsTabOpen?.(false);
    setShowButtons(true);
  };

  const handleUpgrade = async (type) => {
    if (!gameState) return;

    const currentData = UniverseData.getCurrentData();
    let cost;

    switch (type) {
      case 'damage':
        cost = 1000 * Math.pow(2, currentData.damageLevel - 1);
        break;
      case 'energy':
        cost = 1000 * Math.pow(2, currentData.energyLevel - 1);
        break;
      case 'regen':
        cost = 50000 * Math.pow(2, currentData.regenLevel - 1);
        break;
      default:
        return;
    }

    if (UniverseData.handleUpgrade(type, cost)) {
      setGameState(UniverseData.getCurrentData());
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!gameState) return <div>No game state available</div>;

  const remainingEnergyPercentage = (
    (gameState.energyMax - gameState.energy) / gameState.energyMax
  ) * 100;

  return (
    <div className="App">
      <header className="App-header">
        <div className="balance-container">
          <img src={clickerImage} alt="Balance Icon" className="balance-icon" />
          <p>{Math.floor(gameState.totalClicks)}</p>
        </div>
        <div className="energy-container">
          <p>Energy: {Math.floor(gameState.energy)}/{gameState.energyMax}</p>
        </div>
        <div className="clicker-container" ref={clickerRef}>
          <img
            src={clickerImage}
            alt="Clicker"
            className={`clicker-image ${isImageDistorted ? 'distorted' : ''}`}
          />
          <div className="progress-circle">
            <CircularProgressbar
              value={remainingEnergyPercentage}
              maxValue={100}
              styles={buildStyles({
                pathColor: '#b20bff',
                textColor: '#fff',
                trailColor: '#07ffff',
                backgroundColor: '#07ffff'
              })}
            />
          </div>
          {damageIndicators.map(indicator => (
            <DamageIndicator
              key={indicator.id}
              x={indicator.x}
              y={indicator.y}
              damage={indicator.damage}
            />
          ))}
        </div>

        {showButtons && (
          <div className="tabs">
            <button
              className={activeTab === 'UPGRADE' ? 'active' : ''}
              onClick={() => handleTabOpen('UPGRADE')}
            >
              UPGRADE
            </button>
            <button
              className={activeTab === 'BOOST' ? 'active' : ''}
              onClick={() => handleTabOpen('BOOST')}
            >
              GAMES
            </button>
            <button
              className={activeTab === 'SOON' ? 'active' : ''}
              onClick={() => handleTabOpen('SOON')}
            >
              REF
            </button>
          </div>
        )}

        {isTabOpenState && (
          <div className={`tab-content ${isTabOpenState ? 'open' : ''}`}>
            <button className="back-button" onClick={handleBackButtonClick}>
              Back
            </button>
            {activeTab === 'UPGRADE' && (
              <UpgradeTab
                totalClicks={gameState.totalClicks}
                damageUpgradeCost={1000 * Math.pow(2, gameState.damageLevel - 1)}
                energyUpgradeCost={1000 * Math.pow(2, gameState.energyLevel - 1)}
                regenUpgradeCost={50000 * Math.pow(2, gameState.regenLevel - 1)}
                damageLevel={gameState.damageLevel}
                energyLevel={gameState.energyLevel}
                regenLevel={gameState.regenLevel}
                onUpgrade={handleUpgrade}
              />
            )}
            {activeTab === 'BOOST' && <BoostTab />}
            {activeTab === 'SOON' && <SoonTab userData={gameState} />}
          </div>
        )}
      </header>
    </div>
  );
}

export default EatsApp;
